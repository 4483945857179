<template>
  <!-- 文章详情 -->
  <Layout>
    <div class="main-article">
      <div class="md-article-1" v-ani.fade-up>
        <div class="base-width flex-middle">
          <!-- 面包屑 -->
          <BreadCrumb :list='routes' />
          <!-- 返回列表 -->
          <router-link class="back color-blue s16 font-light flex-middle" to="/news">
            <span>返回列表</span>
            <img src="@/assets/images/icon-back.png">
          </router-link>
        </div>
      </div>
      <div class="md-article-2">
        <div class="base-width">
          <!-- 大家都在看 -->
          <div class="lf">
            <HotNews v-ani.fade-up :listArr="baseObj.everyone_watching" />
          </div>
          <!-- 中间 -->
          <div class="main">
            <!-- 详情 -->
            <div class="details c-card" v-ani.fade-up>
              <h1 class="title s26 font-medium text-center">{{ infoObj.title }}</h1>
              <div class="data s16 color-9 flex-center">
                <span class="item">发布人：{{ infoObj.author }}</span>
                <span class="item">发布日期：{{ infoObj.publish_time | dateFormat('yyyy/MM/dd HH:mm') }}</span>
              </div>
              <div class="editor s16 color-4">
                <div v-html="infoObj.content"></div>
              </div>
              <div class="page s16 color-2 font-medium">
                <div class="page-item">
                  <router-link :to="'/article?id=' + last_info?.article_id" tag="a" target="_blank">
                    上一页：{{ last_info?.title }}
                  </router-link>
                </div>
                <div class="page-item">
                  <router-link :to="'/article?id=' + next_info?.article_id" tag="a" target="_blank">
                    下一页：{{ next_info?.title }}
                  </router-link>
                </div>
              </div>
            </div>
            <!-- 评论 -->
            <div class="md-comment c-card" v-ani.fade-up>
              <div class="flex-middle s22 font-medium row-1">
                <img src="@/assets/images/icon-msg.png">
                <span>{{ commentCount }}条评论</span>
              </div>
              <!-- 已登录 -->
              <div class="row-2" v-if="isLogin">
                <img src="@/assets/images/avatar.png" class="avatar">
                <form class="rg s16">
                  <textarea class="ipt color-9" placeholder="请输入..." v-model="replyVal"></textarea>
                  <BaseButton tag='button' color='green' class="font-light btn" @click="replyClick">回复</BaseButton>
                </form>
              </div>
              <!-- 未登录 -->
              <div class="row-2 row-2-2" v-else>
                <span class="t1 s18 color-2">评论</span>
                <div class="t2 flex-center s16 color-9" @click="jumpLogin">请先登录/注册</div>
              </div>
              <div class="list">
                <template v-for="(item, index) in commentListArr">
                  <div class="li" :key="index">
                    <img :src="item.head_pic" class="avatar">
                    <div class="text">
                      <div class="tit">
                        <div class="name s18 font-medium color-2">{{ item.nickname }}</div>
                        <div class="time s14 color-9">{{ item.add_time }}</div>
                      </div>
                      <div class="desc s14 color-6">{{ item.title }}</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { InformationInfoApi, InformationBaseApi, InformationCommentListApi, InformationReplyArticleApi } from "@/request/api/service";
export default {
  created() {
    InformationBaseApi().then(({ status, data }) => {
      if (status == 200) {
        this.baseObj = data;
      }
    })
    InformationInfoApi({ article_id: this.$route.query.id }).then(({ status, data }) => {
      if (status == 200) {
        this.infoObj = data.info;
        this.last_info = data.last_info;
        this.next_info = data.next_info;
      }
    })
    let token = localStorage.getItem('token');
    if (token) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    this.commentListFn();
  },
  data() {
    return {
      infoObj: {},
      last_info: {},
      next_info: {},
      baseObj: {
        everyone_watching: []
      },
      isLogin: false,
      getData: {
        article_id: this.$route.query.id,
        p: 1,
        page_num: 100,
      },
      commentCount: 0,
      commentListArr: [],
      routes: [
        { name: '首页', link: '/' },
        { name: '行业资讯', link: '/news' },
        { name: '资讯详情', link: '' }
      ],
      replyVal: ''
    };
  },
  methods: {
    commentListFn() {
      InformationCommentListApi(this.getData).then(({ status, data }) => {
        if (status == 200) {
          this.commentCount = data.count;
          this.commentListArr = data.list;
        }
      })
    },
    // 回复
    replyClick() {
      InformationReplyArticleApi({ article_id: this.$route.query.id, content: this.replyVal }).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success('回复成功');
          this.replyVal = '';
          this.commentListFn();
        }
      })
    },
    jumpLogin() {
      this.$router.push('/login');
    },
  }
};
</script>

<style lang="scss" scoped>
.md-article-1 {
  padding: 0.26rem 0 .21rem;

  .base-width {
    justify-content: space-between;
  }

  .back {
    img {
      width: 0.11rem;
      margin-left: .04rem;
    }

    &:hover {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.md-article-2 {
  padding-bottom: .4rem;

  .base-width {
    display: flex;
    justify-content: space-between;
  }

  .lf {
    width: 3.26rem;
  }

  .main {
    width: 12.32rem;
  }
}

.details {
  padding: .56rem .4rem;

  .data {
    margin-top: .15rem;

    .item {
      margin-right: .25rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .editor {
    border-top: 1px solid #e6f2f8;
    margin-top: .17rem;
    line-height: calc(30 / 16 * 1em);
    padding-top: .22rem;
    padding-bottom: .33rem;
  }

  .page {
    border-top: 1px solid #e6f2f8;
    padding-top: .36rem;

    .page-item {
      margin-bottom: .19rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      &:hover {
        color: $blue;
      }
    }
  }
}

.md-comment {
  margin-top: .17rem;
  padding: .4rem;
  padding-bottom: .61rem;

  .row-1 {
    img {
      width: 0.23rem;
      margin-right: .09rem;
    }
  }

  .row-2 {
    display: flex;
    align-items: flex-start;
    margin-top: .24rem;

    .avatar {
      width: .5rem;
      height: 0.5rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: .18rem;
    }

    .rg {
      flex: 1;
    }

    .ipt {
      display: block;
      width: 100%;
      height: 1.5rem;
      background-color: #ffffff;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: .15rem .22rem;
      line-height: 1.5;
      color: #333;
      transition: .3s;

      &::placeholder {
        color: #999;
      }

      &:focus {
        border-color: $blue;
      }
    }

    .btn {
      display: block;
      width: 1.2rem;
      margin-left: auto;
      margin-top: .2rem;
      text-align: center;
      // border: none;
      // line-height: calc(40 / 16 * 1em);
      // border-radius: .04rem;
      // cursor: pointer;
    }

    &-2 {
      margin-bottom: .4rem;

      .t1 {
        margin-right: .32rem;
      }

      .t2 {
        flex: 1;
        height: 1.5rem;
        background: #ecf5fa;
        border-radius: .04rem;
        cursor: pointer;
      }
    }
  }

  .list {
    border-top: 1px solid #e6f2f8;
    margin-top: .3rem;

    .li {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #e6f2f8;
      padding: .3rem 0 .26rem;
    }

    .avatar {
      width: 0.5rem;
      height: .5rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: .16rem;
    }

    .tit {
      display: flex;
      align-items: flex-end;
      margin-top: .03rem;
    }

    .name {
      margin-right: .13rem;
    }

    .desc {
      line-height: 1.5;
      margin-top: 0.06rem;
    }
  }
}
</style>